import React, { Component } from "react";
import ALPR from "../Components/img/Screenshot from 2025-03-16 13-13-06.png";
import Rootly from "../Components/img/logomockup6.png";
import RootlyScreenshot1 from "../Components/img/criooed2.png";
import Hangle from "../Components/img/HangleLogo1.png";
import HangleScreenshot1 from "../Components/img/hangle-cropped.png";
import HangleScreenshot2 from "../Components/img/criooed2.png";
import Scramble from "../Components/img/ScrambleLogo.png";
import ScrambleScreenshot1 from "../Components/img/scramble-cropped.png";
import ThePlug from "../Components/img/ThePlug.png";
import ThePlugScreenshot1 from "../Components/img/Screenshot from 2025-03-16 15-01-38.png";
import ViewerScreenshot from "../Components/img/Screenshot from 2025-03-16 15-00-42.png";

class Projects extends Component {
	render() {
		return (
			<React.Fragment>
				<div className="container mt-4">
					<div className=" mb-4 cardDetails p-3 rounded-3 text-start">
						<div className="d-flex align-items-center">
							<img
								src={Rootly}
								alt="Rootly"
								className="me-2 img-fluid rootly-logo"
							/>

							<h5 className="text-dark mb-0 fw-bold">
								Rootly - The Collaborative Ontology Manager
							</h5>
						</div>
						{/* <div
							className="mt-3 rounded overflow-hidden"
							style={{ height: "500px", width: "100%" }}
						>
							<img
								src={RootlyScreenshot1}
								alt="ALPR Screenshot"
								className="w-100 h-100 screenShot"
								style={{ objectFit: "cover" }}
							/>
						</div> */}

						<p className="mt-2 text-dark">
							Rootly is a collaborative ontology management web application that
							allows plant breeders and researchers to create lists of variables
							directly from the CropOntology database using a BrAPI standard.
							Users can also create teams and share their lists within their
							team. Lists are directly integrable with apps like Fieldbook and
							Gridscore for data collection.
						</p>
						<p className="text-muted">
							<strong>Date:</strong> 02/2025
						</p>

						<div className="mb-2 d-flex flex-wrap gap-2 justify-content-center p-3 py-2">
							<span className="badge bg-javascript">JavaScript</span>
							<span className="badge bg-success">Vue.js</span>
							<span className="badge bg-danger">Node</span>
							<span className="badge bg-primary">Python</span>
							<span className="badge bg-pyramid">Pyramid</span>
							<span className="badge bg-neo4j">Neo4j</span>
						</div>

						<a
							href="https://github.com/Othmanali02/Rootly"
							target="_blank"
							className="btn bg-view w-100"
						>
							View Project
						</a>
					</div>

					<div className=" mb-4 cardDetails secondary p-3 rounded-3 text-start">
						<div className="d-flex justify-content-between align-items-center">
							<div className="d-flex align-items-center ms-auto">
								<h5 className="fw-bold mb-0">
									GeoJSON Land Parcel Viewer and Manager
								</h5>
							</div>
						</div>

						{/* <div
							className="mt-3 rounded overflow-hidden"
							style={{ height: "500px", width: "100%" }}
						>
							<img
								src={ViewerScreenshot}
								alt="Viewer Screenshot"
								className="w-100 h-100 screenShot"
								style={{ objectFit: "cover" }}
							/>
						</div> */}

						<p className="mt-2 ">
							A web application that visualizes land parcels using GeoJSON and
							Leaflet, allowing users to generate GeoJSON data through an
							advanced form. The project dives deep into data engineering and
							works with open-source data sources to get
						</p>
						<p className="text-muted">
							<strong>Date:</strong> 09/2024
						</p>

						<div className="mb-2 d-flex flex-wrap gap-2 justify-content-center p-3 py-2">
							<span className="badge bg-javascript me-1">JavaScript</span>
							<span className="badge bg-success me-1">Vuejs</span>
							<span className="badge bg-info me-1">Leaflet</span>

							<span className="badge bg-primary me-1">Python</span>
							<span className="badge bg-danger me-1">Postgres</span>
						</div>

						<a
							href="https://viewer.ardbase.org"
							target="_blank"
							className="btn bg-view secondary w-100"
						>
							View Project
						</a>
					</div>

					<div className=" mb-4 cardDetails p-3 rounded-3 text-start">
						<div className="d-flex align-items-center">
							<img
								src={Scramble}
								alt="Scramble"
								className="me-2 scramble-logo"
							/>

							<h5 className="text-dark fw-bold mb-0">
								Anagram Scramble - The Daily Anagram Game
							</h5>
						</div>
						{/* <div
							className="mt-3 rounded overflow-hidden"
							style={{ height: "500px", width: "100%" }}
						>
							<img
								src={ScrambleScreenshot1}
								alt="Scramble Screenshot"
								className="w-100 screenShot h-100"
								style={{ objectFit: "cover" }}
							/>
						</div> */}

						<p className="mt-2 text-dark">
							Each day there will be a set of scrambled letters, and your goal
							is to re-arrange them to form valid words. You will need to think
							creatively and utilize your vocabulary skills to find a atleast
							one of the possible word combinations in the provided letters.
						</p>
						<p className="text-muted">
							<strong>Date:</strong> 11/2023
						</p>

						<div className="mb-2 d-flex justify-content-center">
							<span className="badge bg-javascript me-1">JavaScript</span>
							<span className="badge bg-primary me-1">React</span>
						</div>

						<a
							href="https://anagram-scramble.com"
							target="_blank"
							className="btn bg-view w-100"
						>
							View Project
						</a>
					</div>

					<div className=" mb-4 cardDetails secondary p-3 rounded-3 text-start">
						<div className="d-flex justify-content-between align-items-center">
							<div className="d-flex align-items-center ms-auto">
								<h5 className="fw-bold mb-0">Hangle - Hangman for Countries</h5>
								<img
									src={Hangle}
									alt="Hangle"
									className="ms-2"
									style={{ width: "200px", height: "auto" }} // Adjust width as needed
								/>
							</div>
						</div>

						{/* <div
							className="mt-3 rounded overflow-hidden"
							style={{ height: "500px", width: "100%" }}
						>
							<img
								src={HangleScreenshot1}
								alt="Hangle Screenshot"
								className="w-100 screenShot h-100"
								style={{ objectFit: "cover" }}
							/>
						</div> */}

						<p className="mt-2 ">
							Hangle is a daily game of Hangman for countries. Each day there
							will be a different country chosen randomly from a set of over 200
							countries. You have to guess which country it is with the least
							number of mistakes. Every incorrect letter you try will turn red,
							and the stick-man drawing will have another part drawn. You will
							have Seven trys before the drawing is complete, and the stick-man
							is hanged.{" "}
						</p>
						<p className="text-muted">
							<strong>Date:</strong> 03/2023
						</p>

						<div className="mb-2 d-flex justify-content-center">
							<span className="badge bg-javascript me-1">JavaScript</span>
							<span className="badge bg-primary me-1">React</span>
						</div>

						<a
							href="https://hangle-geo.com"
							target="_blank"
							className="btn bg-view secondary w-100"
						>
							View Project
						</a>
					</div>

					<div className=" mb-4 cardDetails p-3 rounded-3 text-start">
						<div className="d-flex align-items-center">
							<h5 className="text-dark mb-0">
								ALPR (Using Raspberry Pi 3b+, Python)
							</h5>
						</div>
						{/* <div
							className="mt-3 rounded overflow-hidden"
							style={{ height: "400px", width: "100%" }}
						>
							<img
								src={ALPR}
								alt="ALPR Screenshot"
								className="w-100 screenShot h-100"
								style={{ objectFit: "cover" }}
							/>
						</div> */}

						<p className="mt-2 text-dark">
							An Automatic License Plate Recognition system using Raspberry Pi
							and Python.
						</p>
						<p className="text-muted">
							<strong>Date:</strong> 8/2021
						</p>

						<div className="mb-2 d-flex justify-content-center">
							<span className="badge bg-primary me-1">Python</span>
							<span className="badge bg-secondary me-1">Raspberry Pi</span>
							<span className="badge bg-success">OpenCV</span>
						</div>

						<a
							href="https://github.com/Othmanali02/License-Plate-Recognition-Infinite"
							target="_blank"
							className="btn bg-view w-100"
						>
							View Project
						</a>
					</div>

					<div className=" mb-4 cardDetails secondary p-3 rounded-3 text-start">
						<div className="d-flex justify-content-between align-items-center">
							<div className="d-flex align-items-center ms-auto">
								<h5 className="fw-bold mb-0">
									The Plug - Spotify Trivia and Statistics
								</h5>
								<img
									src={ThePlug}
									alt="ThePlug"
									className="ms-2"
									style={{ width: "150px", height: "auto" }} // Adjust width as needed
								/>
							</div>
						</div>

						{/* <div
							className="mt-3 rounded overflow-hidden"
							style={{ height: "590px", width: "100%" }}
						>
							<img
								src={ThePlugScreenshot1}
								alt="ThePlugScreenshot1"
								className="w-100 screenShot h-100"
								style={{ objectFit: "cover" }}
							/>
						</div> */}

						<p className="mt-2">
							This is a Trivia and Statistics application for Spotify that I
							made in late 2022. The Plug generates a quiz based on your
							listening history and allows you to share your score with your
							friends. I stopped working on this project because the Spotify API
							rules do not allow developers to use the API to create Trivia
							quizzes which is why this project never went fully public, I can
							still allow up to 25 emails to use it, so that's friends and
							family pretty much.
						</p>
						<p className="text-muted">
							<strong>Date:</strong> 11/2022
						</p>

						<div className="mb-2 d-flex justify-content-center">
							<span className="badge bg-javascript me-1">JavaScript</span>
							<span className="badge bg-primary me-1">React</span>
						</div>

						<a
							href="https://theplugforspotify.netlify.app"
							target="_blank"
							className="btn bg-view secondary w-100"
						>
							View Project
						</a>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Projects;
