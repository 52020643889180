import React, { Component } from "react";
import ln from "./img/linkedIn.png";
import git from "./img/25231.png";
import mail from "./img/561127.png";

class Contact extends Component {
	handleEmail = () => {
		window.location = "mailto:othman90hijawi@gmail.com";
	};
	handleGithub = () => {
		window.open("https://github.com/Othmanali02", "_blank").focus();
	};
	handleLinkedin = () => {
		window
			.open("https://www.linkedin.com/in/othman-ali-252008198/", "_blank")
			.focus();
	};
	render() {
		return (
			<React.Fragment>
				<div className="contact">
					<h1 className="prob">Contact Information</h1>
					<h5 className="dec">
						You can find my GitHub, LinkedIn, and Email below!
					</h5>

					<div className="container">
						<div className="grid">
							<div className="social">
								<h1>
									<span className="linkedIn">L</span>
									<span className="linkedIn">i</span>
									<span className="linkedIn">n</span>
									<span className="linkedIn">k</span>
									<span className="linkedIn">e</span>
									<span className="linkedIn">d</span>
									<span className="linkedIn">I</span>
									<span className="linkedIn">n</span>
								</h1>
								<img className="icon" src={ln} alt="" />

								<button
									onClick={this.handleLinkedin}
									style={{ cursor: "none" }}
									className="btn btn-primary"
								>
									Connect With Me
								</button>
							</div>
							<div className="social">
								<h1>
									<span className="gitHub">G</span>
									<span className="gitHub">i</span>
									<span className="gitHub">t</span>
									<span className="gitHub">H</span>
									<span className="gitHub">u</span>
									<span className="gitHub">b</span>
								</h1>
								<img className="icon" src={git} alt="" />

								<button
									onClick={this.handleGithub}
									style={{ cursor: "none" }}
									className="btn btn-primary"
								>
									Check out my Github
								</button>
							</div>
							<div className="social">
								<h1>
									<span className="gmail">E</span>
									<span className="gmail">m</span>
									<span className="gmail">a</span>
									<span className="gmail">i</span>
									<span className="gmail">l</span>
								</h1>
								<img className="icon" src={mail} alt="" />

								<button
									style={{ cursor: "none" }}
									onClick={this.handleEmail}
									className="btn btn-primary"
								>
									Shoot me an Email
								</button>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Contact;
