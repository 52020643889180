const myTimeout = setTimeout(myStopFunction, 100);

function myStopFunction() {
	const cursor = document.querySelector(".cursor");
	document.addEventListener("mousemove", (e) => {
		const offsetX = window.scrollX; // Offset for horizontal scroll
		const offsetY = window.scrollY; // Offset for vertical scroll

		cursor.style.left = e.pageX - offsetX + "px";
		cursor.style.top = e.pageY - offsetY + "px";
	});
	clearTimeout(myTimeout);
}