import React, { Component } from "react";
import image from "./img/IMG_1607.jpg";

class About extends Component {
	render() {
		return (
			<React.Fragment>
				<div className="row about">
					<div className="col bord -2">
						<img className="pic" src={image} alt="" />
					</div>
					<div className="col parag -3">
						<div class="line-5"></div>
						<span className="hi">!أهلاً</span> My name is Othman. I am a Software Engineer from Ramallah, passionate about building intuitive and high performing web applications. Over the past few years I have worked on everything from geospatial research platforms to highly scalable web apps — solving complex problems with clean and efficient code.
						
						We live in a time where it is becoming harder for projects to go from Zero to One (create a breakthrough), so it makes it
						necessary to have a keen eye for ideas that can potentially make a
						difference. I aspire to work on projects that push the boundaries and bring meaningful impact.
						
					</div>
				</div>
				<div className="row skill m-4">
					<div className="list">
						{/* <span className="hi hhh">Skills</span> */}

						<div>
							<h3 className="text-black">Frontend</h3>
							<span className="bubble">Reactjs</span>
							<span className="bubble">Vuejs</span>
							<span className="bubble">Svelte</span>
							<span className="bubble">HTML/CSS</span>
							<span className="bubble">SEO</span>
							<span className="bubble">UX</span>
						</div>
						<div class="vl"></div>

						<div>
							<h3  className="text-black">Backend</h3>
							<span className="bubble">Java</span>
							<span className="bubble">Django</span>
							<span className="bubble">Node</span>
							<span className="bubble">PHP</span>

						</div>
						<div class="vl"></div>
						<div>
							<h3  className="text-black">Databases</h3>
							<span className="bubble">MySQL</span>
							<span className="bubble">Mongodb</span>
							<span className="bubble">PostgreSQL</span>
							<span className="bubble">Neo4j</span>

						</div>
						<div class="vl"></div>
						<div>
							<h3 className="text-black">Other</h3>
							<span className="bubble">Linux</span>
							<span className="bubble">Docker</span>
							<span className="bubble">Git</span>
							<span className="bubble">CI/CD</span>
							<span className="bubble">Nginx</span>
							<span className="bubble">Postman</span>

						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default About;
